<template>
    <div>
        <!--begin::Item-->
          <div class="timeline-item align-items-start" v-bind:key="item._id">
            <!--begin::Label-->
            <div
              class="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3"
            >
              {{ item.createdAt | formatTime }}
            </div>
            <!--end::Label-->

            <!--begin::Badge-->
            <div class="timeline-badge">
              <i class="icon-xxl" v-bind:class="getBadge"></i>
            </div>
            <!--end::Badge-->

            <!--begin::Text-->
            <div class="timeline-content text-dark-50 d-flex">
              <span
                v-bind:class="{
                  'font-weight-bolder text-dark-75': getBold,
                  'mr-4': item.images
                }"
                v-html="getText"
              ></span>

              <!--begin::Section-->
              <div class="d-flex align-items-start mt-n2">
                <template v-for="(image, i) in getImages">
                  <!--begin::Symbol-->
                  <a
                    href="#"
                    class="symbol symbol-35 symbol-light-success mr-2"
                    v-bind:key="i"
                  >
                    <span class="symbol-label">
                      <img
                        :src="image.img"
                        class="h-75 align-self-end"
                        :alt="image.alt"
                      />
                    </span>
                  </a>
                  <!--end::Symbol-->
                </template>
              </div>
              <!--end::Section-->
            </div>
            <!--end::Text-->
          </div>
          <!--end::Item-->        
    </div>
</template>
<script>

export default{
    props:{
        item:{
            type: Object,
            required: true
        }
    },
    methods:{

    },
    computed:{
        getBadge(){
            switch (this.item.action) {
                case 'PROCESS_RECOGIDA':
                case 'LOGIN':
                    return 'fa fa-genderless text-success'                    
            
                case 'CREATE_TAKEAWAY_ORDER':
                    return 'fa fa-genderless text-danger'  
                case 'CREATE_DELIVERY_ORDER':
                    return 'fa fa-genderless text-danger'      
                case 'UPDATE_SELLER':
                    return 'fa fa-genderless text-success'    
                case 'PROCESS_REFUND':
                    return 'fa fa-genderless text-success'    

                default:
                    return '';
            }
        },
        getImages(){
            switch (this.item.action) { 
                case 'PROCESS_RECOGIDA':
                    return  [
                    {
                        url: "#",
                        img: process.env.BASE_URL + "media/svg/avatars/004-boy-1.svg",
                        alt: "boy"
                    },
                    {
                        url: "#",
                        img: process.env.BASE_URL + "media/svg/avatars/002-girl.svg",
                        alt: "girl"
                    }
                ]
            
                default:
                    return [];
            }
        },
        getBold(){
            switch (this.item.action) { 
                case 'PROCESS_RECOGIDA':
                    return  false
            
                case 'LOGIN':
                    return  false

                case 'UPDATE_SELLER':
                    return  false

                default:
                    return [];
            }
        },
        getText(){
            switch (this.item.action) {
                case 'LOGIN':                    
                    return 'Arrancamos el servicio 💪💪'

                case 'PROCESS_RECOGIDA':                    
                    return 'Pedido recogido por el cliente '

                case 'CREATE_TAKEAWAY_ORDER':
                    return 'Nuevo pedido para recoger'  
                
                case 'CREATE_DELIVERY_ORDER':
                    return 'Nuevo pedido para entregar a domicilio'  
                
                case 'UPDATE_SELLER':
                    return 'Datos actualizados '  
                
                case 'PROCESS_REFUND':
                    return 'Se ha procesado una devolución '  
            
                default:
                    return '';
            }
        }
    }
}

</script>

<style lang="">
    
</style>