<template>
  <!--begin::List Widget 9-->
  <div v-if="!isFetching" class="card card-custom card-stretch gutter-b">
    <!--begin::Header--> 
    <div class="card-header align-items-center border-0 mt-4">
      <h3 class="card-title align-items-start flex-column">
        <span class="font-weight-bolder text-dark">
          Actividad
        </span>
        <span class="text-muted mt-3 font-weight-bold font-size-sm">
          {{ logs.length }} registros de actividad hoy
        </span>
      </h3>
      <div class="card-toolbar">
        <Dropdown2></Dropdown2>
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body pt-4">
      <div class="timeline timeline-5 mt-3">
        <div v-for="(item) in logs" :key="item._id">
          <ActivityItem :item="item" />
        </div>  
      </div>
      <!--end: Items-->
    </div>
    <!--end: Card Body-->
  </div>
  <!--end: Card-->
  <div v-else>
    <v-card flat min-height="400px">
        <h1>Cargando acciones ...</h1>  {{ isFetching }}
        <AppSpinner style="align-self-center"/>
    </v-card>
  </div>  
  <!--end: List Widget 9-->
</template>

<script>
import Dropdown2 from "@/view/content/dropdown/Dropdown2.vue";
import ActivityItem from '@/view/content/widgets/list/ActivityItem.vue'
import AppSpinner from "@/view/content/AppSpinner.vue";
import { mapGetters, mapState } from "vuex";

export default {  
  name: "widget-9",
  props:{
    logs:{
      type: Array,
      required: false
    }
  },
  data() {
    return {
      list: [
        {
          time: "10:30",
          desc: "Arrancamos el servicio 💪💪",
          badge: "fa fa-genderless text-success"
        },
        {
          time: "3 hr",
          desc: "AEOL meeting with",
          bold: true,
          badge: "fa fa-genderless text-danger",
          images: [
            {
              url: "#",
              img: process.env.BASE_URL + "media/svg/avatars/004-boy-1.svg",
              alt: "boy"
            },
            {
              url: "#",
              img: process.env.BASE_URL + "media/svg/avatars/002-girl.svg",
              alt: "girl"
            }
          ]
        },
        {
          time: "14:37",
          desc: `Submit initial budget - <a href="#" class="text-primary">USD 700</a>.`,
          badge: "fa fa-genderless text-info",
          bold: true
        },
        {
          time: "16:50",
          desc: "Stakeholder meeting scheduling.",
          badge: "fa fa-genderless text-danger"
        },
        {
          time: "17:30",
          desc: "Project scoping & estimations with stakeholders.",
          badge: "fa fa-genderless text-success"
        },
        {
          time: "21:03",
          desc: `New order placed <a href="#" class="text-primary">#XF-2356</a>.`,
          badge: "fa fa-genderless text-warning",
          bold: true
        },
        {
          time: "21:07",
          desc:
            "Company BBQ to celebrate the last quater achievements and goals.",
          badge: "fa fa-genderless text-danger"
        },
        {
          time: "20:30",
          desc: "Marketing campaign planning with customer.",
          badge: "fa fa-genderless text-info"
        }
      ]
    };
  },
  components: {
    Dropdown2,
    AppSpinner,
    ActivityItem
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapState({        
        isFetching: state => state.logs.isFetching,                    
    }),
  }  
};
</script>
